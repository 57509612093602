import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from '../../app.main.component';
import {MenuItem} from 'primeng/api';
import {PermissionService} from '../../pages/shared/services/permissions/permission.service';
import {TVersions} from '../../pages/authentication/login/models/login-response.model';
import {ObjectHelpers} from '../../pages/shared/helpers/object-helpers';
import {Logger} from '../../pages/shared/services/logger/logger';
import {Globals} from '../../pages/shared/globals';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
	public model: MenuItem[] = [];
	public versions: TVersions;
	public showVersions: boolean = false;

	constructor(public app: AppMainComponent,
				private _permisssionService: PermissionService) {
	}

	public async ngOnInit(): Promise<void> {
		await this.enableBranchFeatures();
		await this.getFromCache();
	}

	private async getFromCache(): Promise<void> {
		try {

			if (!ObjectHelpers.isNullUndefinedOrEmpty(Globals.UserInformation)) {
				this.versions = Globals.UserInformation.versions;
			}
		} catch (e) {
			Logger.logError('Failed to get info from cache: ' + e);
		}
	}

	protected showHideVersions(): void {
		this.showVersions = !this.showVersions;
	}

	private async enableBranchFeatures(): Promise<void> {

		try {

			this.model.push(
				{
					label: 'Main', icon: 'pi pi-home',
					routerLink: ['/main'],
				},
				{
					label: 'Stock Management', icon: 'pi pi-car',
					visible: await this._permisssionService.validatePermission(['Admin', 'Stock Management']),
					routerLink: ['/stock-management'],
				},
				{
					label: 'Clients', icon: 'pi pi-users',
					visible: await this._permisssionService.validatePermission(['Admin', 'Clients Menu']),
					routerLink: ['/clients'],
				},
				{
					label: 'Suppliers', icon: 'pi pi-shopping-cart',
					visible: await this._permisssionService.validatePermission(['Admin', 'Suppliers Menu']),
					routerLink: ['/suppliers'],
				},
				{
					label: 'Company Settings', icon: 'pi pi-cog',
					visible: await this._permisssionService.validatePermission(['Admin', 'Company Settings']),
					routerLink: ['/company/settings'],
				},
				{
					label: 'Reports', icon: 'pi pi-print',
					visible: await this._permisssionService.validatePermission(['Admin', 'Reports']),
					routerLink: ['/reports']
				});
		} catch (e) {
			Logger.logError('Failed to enable branch features: ' + e);
		}
	}
}
