import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../../login/auth.service';
import { PermissionService } from '../../../shared/services/permissions/permission.service';
import { ObjectHelpers } from '../../../shared/helpers/object-helpers';
import {Logger} from '../../../shared/services/logger/logger';
import {Globals} from '../../../shared/globals';

@Injectable({
	providedIn: 'root'
})
export class CompanySelectAuthGuard implements CanActivate {

	constructor(private _router: Router,
		private _authService: AuthService,
		private _permissionService: PermissionService
	) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

		try {

			if (ObjectHelpers.isNullUndefinedOrEmpty(Globals.UserInformation)) {
				return await this.logout();
			}

			const isTokenExpired = this._authService.isTokenExpired(Globals.UserInformation.accessToken);
			if (isTokenExpired) {
				return await this.logout();
			}
			return true;
		} catch (error) {
			Logger.logError(error);
			await this.logout();
		}
	}

	private async logout(): Promise<UrlTree> {
		try {
			this._authService.logout();
			this._permissionService.flushRolesAndPermissions();
			return this._router.parseUrl('/login');
		} catch (e) {
			Logger.logError('Failed to logout' + e);
		}
	}
}
