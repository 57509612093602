import {Injectable} from '@angular/core';
import {CompanyBranchFeature} from '../../models/company-branch-feature.model';
import {Globals} from '../../../shared/globals';
import {CacherService} from '../../../shared/services/cacher/cacher.service';
import {Router} from '@angular/router';
import {PermissionService} from '../../../shared/services/permissions/permission.service';
import {Logger} from '../../../shared/services/logger/logger';
import {ObjectHelpers} from '../../../shared/helpers/object-helpers';
import {StringHelpers} from '../../../shared/helpers/string-helpers';


@Injectable({
	providedIn: 'root'
})
export class CompanyService {
	public allBranchIds: number[];

	constructor(
		private cacherService: CacherService,
		private permissionService: PermissionService,
		private router: Router
	) {}

	public async companySelected(companyBranchFeature: CompanyBranchFeature): Promise<void> {

		Globals.CompanyBranchFeatures = Globals.UserInformation.user.companyBranchFeatures.filter(x =>
			x.companyId === companyBranchFeature.companyId
			&& x.branchId === companyBranchFeature.branchId);

		let companyBranchFeatures = Globals.UserInformation.user.companyBranchFeatures.filter(
			x => x.companyId === companyBranchFeature.companyId);

		if (ObjectHelpers.isNullUndefinedOrEmpty(companyBranchFeatures)) {
			Globals.UserInformation.user.companyBranchFeatures = [companyBranchFeature];

			companyBranchFeatures = Globals.UserInformation.user.companyBranchFeatures.filter(
				x => x.companyId === companyBranchFeature.companyId);
		}

		await this.branchSelection(companyBranchFeatures, companyBranchFeature.companyId);
	}

	public async branchSelection(selectedCompany: CompanyBranchFeature[], companyId: number): Promise<void> {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(selectedCompany) && !StringHelpers.isNumberNullOrUndefined(companyId)) {

			const uniqueBranches = this.getUniqueBranches(selectedCompany);
			Globals.UserInformation.user.companyBranchFeatures = uniqueBranches;
			this.allBranchIds = uniqueBranches.map(feature => feature.branchId);

			await this.setCachingCompanySelect(companyId, this.allBranchIds);
		}
	}

	private getUniqueBranches(features: CompanyBranchFeature[]): CompanyBranchFeature[] {

		if (!ObjectHelpers.isNullUndefinedOrEmpty(features)) {
			const uniqueBranchesMap = new Map<number, CompanyBranchFeature>();

			features.forEach(feature => {
				uniqueBranchesMap.set(feature.branchId, feature);
			});

			return Array.from(uniqueBranchesMap.values());
		}
	}

	public async setCachingCompanySelect(companyId: number, branchIds: number[]): Promise<void> {

		try {
			Globals.BranchIds = branchIds;
			Globals.CompanyId = companyId;
			Globals.RegionId = Globals.UserInformation.companyAddress.regionId;

			let permissions = Globals.UserInformation.employeeUserInformation.find(x => x.companyId === companyId);

			if (ObjectHelpers.isNullUndefinedOrEmpty(permissions)) {

				if (!ObjectHelpers.isNullUndefinedOrEmpty(Globals.UserInformation)) {

					if (Array.isArray(Globals.UserInformation.employeeUserInformation)) {
						permissions = Globals.UserInformation.employeeUserInformation.find(x => x.companyId === companyId);

						if (permissions) {
							Globals.UserInformation.employeeUserInformation = [permissions];
						}
					}
				}


			}

			this.permissionService.addRoleWithPermissions(permissions);
			Globals.UserInformation.employeeUserInformation = [permissions];

			if (this.router.url === '/company-select') {
				await this.router.navigate(['/']);
			}
		} catch (e) {
			Logger.logError('Failed to set caching company', e);
		}
	}
}
