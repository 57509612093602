import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {StringHelpers} from '../../shared/helpers/string-helpers';
import {Globals} from '../../shared/globals';
import {CacherService} from '../../shared/services/cacher/cacher.service';
import {AuthService} from './auth.service';
import {PermissionService} from '../../shared/services/permissions/permission.service';
import {ObjectHelpers} from '../../shared/helpers/object-helpers';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	public constructor(private _router: Router,
					   private _authService: AuthService,
					   private _permissionService: PermissionService,
					   private _cacherService: CacherService) {
	}

	public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {

		if (StringHelpers.isNullOrWhiteSpace(Globals.UserInformation?.accessToken) &&
			localStorage.length === 0 ||
			this._cacherService.hasKey('isRefreshTriggered')) {
			await this.logout();
			return false;
		}

		let authToken: string = Globals.UserInformation?.accessToken;

		if (StringHelpers.isNullOrWhiteSpace(authToken)) {

			if (ObjectHelpers.isNullUndefinedOrEmpty(Globals.UserInformation))
				await this.logout();

			authToken = Globals.UserInformation?.accessToken;
			Globals.CompanyId = Globals.UserInformation?.employeeUserInformation[0].companyId;
			Globals.BranchIds = [Globals.UserInformation?.employeeUserInformation[0].employee.branchId];

			if (!ObjectHelpers.isNullUndefinedOrEmpty(Globals.UserInformation?.employeeUserInformation[0]?.systemUser)) {
				this._permissionService.addRoleWithPermissions(Globals.UserInformation?.employeeUserInformation[0]);
			} else {
				await this.logout();
				return false;
			}
		}

		if (StringHelpers.isNullOrWhiteSpace(authToken) || this._authService.isTokenExpired(authToken)) {
			await this.logout();
			return false;
		}
		return true;
	}

	private async logout(): Promise<void> {
		this._authService.logout();
		this._permissionService.flushRolesAndPermissions();
		await this._router.navigate(['/login']);
	}
}
