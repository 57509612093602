import {Component, Input, OnInit} from '@angular/core';
import {AppMainComponent} from '../../../app.main.component';
import {SelectItemGroup} from 'primeng/api';
import {CacherService} from '../../../pages/shared/services/cacher/cacher.service';
import {UserInformation} from '../../../pages/authentication/login/models/login-response.model';
import {CompanyBranchFeature} from '../../../pages/authentication/models/company-branch-feature.model';
import {ObjectHelpers} from '../../../pages/shared/helpers/object-helpers';
import {BranchService} from '../../../services/global/branch.service';
import {Globals} from '../../../pages/shared/globals';

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
	@Input('pageHeading') pageHeading: string;
	public branches: Array<SelectItemGroup> = new Array<SelectItemGroup>();

	public selectedBranch: any[] = [];
	public showNotifications: boolean = false;

	constructor(public app: AppMainComponent,
				private _branchService: BranchService,
				public _cacherService: CacherService) {
	}

	public async ngOnInit(): Promise<void> {
		try {
			this.selectedBranch = Globals.BranchIds;
			await this.multiBranchSelect();

		} catch (e) {
			console.error('Failed to load initial call: ' + e);
		}
	}


	public onHideNotifications = (): void => {
		this.showNotifications = false;
	}

	private async multiBranchSelect(): Promise<void> {

		try {

			Globals.UserInformation.user.companyBranchFeatures.forEach((company: CompanyBranchFeature) => {

				if (ObjectHelpers.isNullUndefinedOrEmpty(this.branches)) {
					this.branches.push({
						label: company.companyName,
						items: [{
							label: company.branchName,
							value: company.branchId
						}]
					});
				} else {
					const existingBranch = this.branches[0].items
						.find(item => item.value === company.branchId);

					if (!existingBranch) {
						this.branches[0].items.push({
							label: company.branchName,
							value: company.branchId
						});
					}
				}
			});
			this._branchService.allBranches = this.branches[0].items;
		} catch (e) {
			console.error('Error selecting branch: ' + e);
		}
	}

	// public async branchSelected($event: any): Promise<void> {
	// 	if (!ObjectHelpers.isNullUndefinedOrEmpty($event.value)) {
	// 		Globals.BranchIds = $event.value;
	// 		this._branchService.setBranchIds($event.value);
	// 		this._cacherService.setData(BRANCH_IDS, Globals.BranchIds, { cacheType: CacheType.Local });
	// 	}
	// }

	// public branchSelectionLimit() {
	// 	switch (this._router.url) {
	// 		case '/company/details':
	// 			this.selectionLimit = 1;
	// 			break;
	// 		case '/company/vmg-connect':
	// 			this.selectionLimit = 1;
	// 			break;
	// 		default:
	// 			this.selectionLimit = 100;
	// 	}
	// }

	public showHideNotifications(event: any): void {
		event.stopPropagation();
		this.showNotifications = !this.showNotifications;
	}
}
