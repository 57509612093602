import {Injectable} from '@angular/core';
import {RequestOptions} from '../../models/shared/request-options.model';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../../models/IResponseWrapper';
import {Globals} from '../../pages/shared/globals';
import {CompanyAndBranchPayload} from '../../models/dealership/payloads/company-and-branch-payload';
import {ApiService} from '../shared/api.service';
import {EncryptRequestOrResponse} from '../../models/shared/encrypt-request-or-response';
import {map} from 'rxjs/operators';
import {EncryptionService} from '../../pages/shared/services/encryption.service';
import {IGeneralSettingsResponse} from '../../models/settings/general-settings-response';

@Injectable({
	providedIn: 'root'
})
export class GeneralSettingsService extends EncryptionService<any, any> {
	public generalSettings: IGeneralSettingsResponse;

	constructor(private apiService: ApiService) {
		super();
	}

	public saveSsoCompanyAndSsoBranch(companyAndBranchPayload: CompanyAndBranchPayload, requestOptions: RequestOptions): Observable<ResponseWrapper<CompanyAndBranchPayload>> {

		requestOptions.method = 'post';
		const endpoint: string = 'v1/SaveSsoCompanyAndSsoBranchDetails';
		requestOptions.baseUrl = Globals.DealershipApiBaseUrl;
		const encryptedCompanyAndBranchPayload = this.encryptSingleEntity(companyAndBranchPayload);

		return this.apiService.apiRequest<EncryptRequestOrResponse>(endpoint, encryptedCompanyAndBranchPayload, requestOptions).pipe(
			map((response: EncryptRequestOrResponse) => {
				return this.decryptForSingleResultByType<CompanyAndBranchPayload>(response);
			}));
	}
}
