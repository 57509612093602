import { Injectable } from '@angular/core';
import {GenericEntityService} from '../shared/generic-entity.service';
import {ISystemUser} from '../../models/auth/system-user';
import {ApiService} from '../shared/api.service';
import {CacherService} from '../../pages/shared/services/cacher/cacher.service';
import {NotificationsService} from '../../pages/shared/services/notifications/notifications.service';
import {Globals} from '../../pages/shared/globals';
import {ObjectHelpers} from '../../pages/shared/helpers/object-helpers';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class SystemUserService extends GenericEntityService<ISystemUser, string> {

	constructor(protected apiService: ApiService,
				protected cacherService: CacherService,
				protected notificationsService: NotificationsService
	) {
		const baseUrl: string = Globals.DealershipAuthApiBaseUrl + '/SystemUsers/v1';
		super(notificationsService, apiService, baseUrl);
	}

	public getSystemUserProfileFromCache(): void {

		if (!ObjectHelpers.isNullOrUndefined(Globals.UserInformation)) {
				Globals.SystemUserProfile = _.clone(Globals.UserInformation.user);
		}
	}
}
